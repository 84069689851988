<template>
  <div class="wrap">
    <div class="header">
      <div class="card">
        <div class="top" @click="getDetail">
          <div class="img">
            <van-image
              round
              width="64px"
              height="64px"
              :src="detail.imgUrl"
              v-if="detail.imgUrl"
            />
            <i class="rmy-icon rmy-icon-touxiang" v-if="!detail.imgUrl"></i>
          </div>
          <div class="detail">
            <div class="name">{{ detail.name }}</div>
            <div class="phone">
              <i class="rmy-icon rmy-icon-dianhua"></i>
              <!-- {{ detail.phone.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") }} -->
              {{ detail.phone }}
            </div>
          </div>
          <div class="score">
            <i class="rmy-icon rmy-icon-jifen"></i>
            {{ detail.score }} 积分
          </div>
        </div>
        <div class="bottom">
          <ul v-for="item in doneList" :key="item.value" class="bItem">
            <li>
              <div class="value" :style="{ color: item.color }">
                {{ item.value }}
              </div>
              <div class="label">{{ item.label }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <van-cell-group>
        <van-cell
          title="退出登录"
          is-link
          icon="tuichudenglu"
          icon-prefix="rmy-icon"
          @click="logout"
        />
      </van-cell-group>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, reactive } from "vue";
import { Dialog } from "vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  getAccountDetail,
  getAccountScore,
  getStatistical,
  getPortrait,
} from "@/api/mine";
import { logout as logoutApi } from '@/api/web'
export default {
  setup() {
    const detail = reactive({
      // imgUrl: "https://img.yzcdn.cn/vant/cat.jpeg",
      imgUrl: "",
      name: "",
      phone: "",
      score: "0",
      role: "",
      dept: "",
      roleId: "",
      userId: "",
    });
    const doneList = reactive([
      { label: "待完成", value: "0", color: "#FFA92A" },
      { label: "已完成", value: "0", color: "#2DBD4A" },
      { label: "已过期", value: "0", color: "#F84255" },
    ]);
    const router = useRouter();
    const store = useStore();
    const getDetail = () => {
      router.push({ name: "detail", params: detail });
    };
    const logout = async () => {
      Dialog.confirm({
        title: "退出登录",
        message: "确认是否退出？",
      })
        .then(() => {
          logoutApi();
          store.commit('resetStore');
          sessionStorage.clear();
          sessionStorage.setItem('isLogout', '1'); // 用于标识退出登录，在登录页对这块特殊处理
          router.replace("login");
        })
        .catch(() => {
          // on cancel
        });
    };

    const getScore = async () => {
      const res = await getAccountScore({ userId: store.state.userId });
      if ((res as any).resp_code === 0) {
        detail.score = (res as any).datas;
      }
    };
    const getMineDetail = async () => {
      if (!sessionStorage.getItem('userInfo')) {
        const res = await getAccountDetail();
        if ((res as any).resp_code === 0) {
          const userInfo = (res as any).datas;
          const {
            nameCn,
            userMobile,
            userId,
            roles,
            roleId,
            openId,
            unionId,
          } = userInfo;

          detail.name = nameCn;
          detail.phone = userMobile;
          detail.role = roles[0].roleName;
          detail.dept = roles[0].merchantChName;
          detail.userId = userId;
          detail.roleId = roleId;
          store.commit('updateUserId', userId);
          if (!store.state.openId) {
            store.commit('setOpenId', openId);
          }
          if (!store.state.unionId) {
            store.commit('setUnionId', unionId);
          }
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        }
      } else {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo') as string);
        detail.name = userInfo.nameCn;
        detail.phone = userInfo.userMobile;
        detail.role = userInfo.roles[0].roleName;
        detail.dept = userInfo.roles[0].merchantChName;
        detail.userId = userInfo.userId;
        detail.roleId = userInfo.roleId;
      }
      getScore();
    };
    const getWPStatis = async () => {
      const res = await getStatistical();
      if ((res as any).resp_code === 0) {
        const { datas } = res as any;
        if (!datas) {
          return;
        }
        doneList[0].value = datas.unfinishedCount;
        doneList[1].value = datas.finishedCount;
        doneList[2].value = datas.expiredCount;
      }
    };
    const getImg = async () => {
      if (!sessionStorage.getItem("imgUrl")) {
        const res = await getPortrait() as any;
        if (res.resp_code === 0) {
          detail.imgUrl = res.datas || '';
          sessionStorage.setItem("imgUrl", detail.imgUrl);
        }
      } else {
        detail.imgUrl = sessionStorage.getItem("imgUrl") || '';
      }
    };

    onMounted(() => {
      getMineDetail();
      getWPStatis();
      getImg();
    });
    return {
      detail,
      doneList,
      getDetail,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: $BACKGROUND_COLOR;
  background-image: url("../assets/image/bj@2x.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  .header {
    padding: 67px 16px 16px 16px;
    .card {
      padding: 16px;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(6, 24, 51, 0.04);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        margin-bottom: 16px;
        .img {
          width: 64px;
          height: 64px;
          .rmy-icon {
            font-size: 62px;
            color: #666666;
          }
        }
        .detail {
          padding: 8px;
          .name {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 24px;
            color: #333333;
            letter-spacing: 4px;
            margin-bottom: 6px;
          }
          .phone {
            border-radius: 4px;
            background: #e9f3ff;
            padding: 4px 8px;
            color: #6082e1;
            letter-spacing: 1px;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            .rmy-icon {
              font-size: 14px;
            }
          }
        }
        position: relative;
        .score {
          height: 20px;
          line-height: 20px;
          position: absolute;
          top: 8px;
          right: -16px;
          background: linear-gradient(to right, #ffd242, #feb740);
          color: #ffffff;
          font-size: 15px;
          padding: 4px 8px;
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
          letter-spacing: 1px;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        .bItem {
          position: relative;
          flex: 1;
          text-align: center;
          .value {
            font-size: 24px;
            font-family: DIN;
            font-weight: 500;
            line-height: 30px;
          }
          .label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #989ba1;
          }
        }
        .bItem:nth-child(1)::before,
        .bItem:nth-child(2)::before {
          content: "";
          width: 2px;
          height: 27px;
          background-color: #b8b8b8;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
  .content {
    :deep .van-cell {
      .rmy-icon-yijianfankui {
        color: #3b8bf6;
      }
      .rmy-icon-tuichudenglu {
        color: #f84255;
      }
    }
  }
}
</style>
