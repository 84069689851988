import http from "@/utils/http";
import Qs from 'qs';
import { PasswordParams } from "@/typings/index";

// 个人中心
export const getAccountDetail = () =>
  http({
    url: "/api-user/user/current",
    method: "get",
  });

//根据用户获取积分
export const getAccountScore = (params:object) =>
  http({
    url: "/api-user/tintegrallog/integralByUser?"+ Qs.stringify(params),
    method: "get",
  });

//获取宣传员指令统计
export const getStatistical = () =>
  http({
    url: "/api-instruction/instructionreceive/commentatorInsCount",
    method: "get",
  });

  //获取用户头像
export const getPortrait = () =>
http({
  url: "/api-editingChannels/wxuser/headImgUrl",
  method: "get",
});

//修改密码
export const updatePassword = (data:PasswordParams) =>
http({
  url: "/api-user/user/password",
  method: "put",
  data,
});


