
import { onMounted, reactive } from "vue";
import { Dialog } from "vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  getAccountDetail,
  getAccountScore,
  getStatistical,
  getPortrait,
} from "@/api/mine";
import { logout as logoutApi } from '@/api/web'
export default {
  setup() {
    const detail = reactive({
      // imgUrl: "https://img.yzcdn.cn/vant/cat.jpeg",
      imgUrl: "",
      name: "",
      phone: "",
      score: "0",
      role: "",
      dept: "",
      roleId: "",
      userId: "",
    });
    const doneList = reactive([
      { label: "待完成", value: "0", color: "#FFA92A" },
      { label: "已完成", value: "0", color: "#2DBD4A" },
      { label: "已过期", value: "0", color: "#F84255" },
    ]);
    const router = useRouter();
    const store = useStore();
    const getDetail = () => {
      router.push({ name: "detail", params: detail });
    };
    const logout = async () => {
      Dialog.confirm({
        title: "退出登录",
        message: "确认是否退出？",
      })
        .then(() => {
          logoutApi();
          store.commit('resetStore');
          sessionStorage.clear();
          sessionStorage.setItem('isLogout', '1'); // 用于标识退出登录，在登录页对这块特殊处理
          router.replace("login");
        })
        .catch(() => {
          // on cancel
        });
    };

    const getScore = async () => {
      const res = await getAccountScore({ userId: store.state.userId });
      if ((res as any).resp_code === 0) {
        detail.score = (res as any).datas;
      }
    };
    const getMineDetail = async () => {
      if (!sessionStorage.getItem('userInfo')) {
        const res = await getAccountDetail();
        if ((res as any).resp_code === 0) {
          const userInfo = (res as any).datas;
          const {
            nameCn,
            userMobile,
            userId,
            roles,
            roleId,
            openId,
            unionId,
          } = userInfo;

          detail.name = nameCn;
          detail.phone = userMobile;
          detail.role = roles[0].roleName;
          detail.dept = roles[0].merchantChName;
          detail.userId = userId;
          detail.roleId = roleId;
          store.commit('updateUserId', userId);
          if (!store.state.openId) {
            store.commit('setOpenId', openId);
          }
          if (!store.state.unionId) {
            store.commit('setUnionId', unionId);
          }
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        }
      } else {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo') as string);
        detail.name = userInfo.nameCn;
        detail.phone = userInfo.userMobile;
        detail.role = userInfo.roles[0].roleName;
        detail.dept = userInfo.roles[0].merchantChName;
        detail.userId = userInfo.userId;
        detail.roleId = userInfo.roleId;
      }
      getScore();
    };
    const getWPStatis = async () => {
      const res = await getStatistical();
      if ((res as any).resp_code === 0) {
        const { datas } = res as any;
        if (!datas) {
          return;
        }
        doneList[0].value = datas.unfinishedCount;
        doneList[1].value = datas.finishedCount;
        doneList[2].value = datas.expiredCount;
      }
    };
    const getImg = async () => {
      if (!sessionStorage.getItem("imgUrl")) {
        const res = await getPortrait() as any;
        if (res.resp_code === 0) {
          detail.imgUrl = res.datas || '';
          sessionStorage.setItem("imgUrl", detail.imgUrl);
        }
      } else {
        detail.imgUrl = sessionStorage.getItem("imgUrl") || '';
      }
    };

    onMounted(() => {
      getMineDetail();
      getWPStatis();
      getImg();
    });
    return {
      detail,
      doneList,
      getDetail,
      logout,
    };
  },
};
